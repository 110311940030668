
export const clientSettingsStoreModule = {
  namespaced: true,
  state: {
    IFsList: [],
    IQsList: []
  },
  
  mutations: {
    reset(state)
    {
      state.IFsList = [];
      state.IQsList = [];
      console.log("@@@@ Cleared the store clientSettingsStoreModule");
    },
    updateIFsList(state, IFElement) {
        var found =  false;
        for (let index = 0;  index < state.IFsList.length; index++) {
          const element = state.IFsList[index];
          if(element.id == IFElement.id){
              state.IFsList[index] = IFElement;
              found = true;
          }
        }

        if(!found)  {
          state.IFsList.push(IFElement);
        }

    },
    setIFsList(state, IFList) {
      state.IFsList = IFList
    },
    updateIQsList(state, IQElement) {
        var found =  false;
        for (let index = 0; index < state.IQsList.length; index++) {
          const element = state.IQsList[index];
          if(element.id == IQElement.id){
              state.IQsList[index] = IQElement;
              found = true;
          }
        }

        if(!found)  {
          state.IQsList.push(IQElement);
        }

    },
    setIQsList(state, IQList) {
      state.IQsList = IQList
    },
  },
  
  // ...mapGetters("clientSettingsStoreModule",[
  //   'listeIndicateursIF',
  //   'totalEchellesIFs',
  //   'listeIndicateursIQ',
  //   'totalEchellesIQs'
  //   // ...
  // ])
  getters: {
    listeIndicateursIF: state => {
      return state.IFsList;
    },
    totalEchellesIFs: state => {
      let total = 0;
      for (let index = 0; index < state.IFsList.length; index++) {
        const element = state.IFsList[index];
        total += element.echelleIndicateur;
      }
      return total;
    },

    getEchelleIF: state=> (IFid) => {
      return state.IFsList.find(IFElement => IFElement.id === IFid).echelleIndicateur;
    },

    listeIndicateursIQ: state => {
      return state.IQsList;
    },
    totalEchellesIQs: state => {
      let total = 0;
      for (let index = 0; index < state.IQsList.length; index++) {
        const element = state.IQsList[index];
        total += element.echelleIndicateur;
      }
      return total;
    },

    getEchelleIQ: state=> (IQid) => {
      return state.IQsList.find(IQElement => IQElement.id === IQid).echelleIndicateur;
    },
  }
};
