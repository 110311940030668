<!-- ########################## <Template> ########################## -->
<template>
 
 
    <Dialog ref="refChangePassword"  @hide="fnClose" :closable="false"
                   v-bind:visible="true" :style="{width: '450px',initialHeight:'380px'}" 
                   :header="'Changer mot de passe: '+ this.username" :modal="true">
           
                <div class="p-fluid">
                    <div class="p-field">
                        <label for="passwordActuel">Mot de passe actuel </label>
                        <InputText name="passwordActuel" type="text" placeholder="Saisissez le mot de passe actuel" v-model="passwordActuel" autocomplete="new-password" />
                    </div>

                    <div class="p-field">
                        <label for="password1">Mot de passe</label>
                        <InputText name="password1" type="password" placeholder="Saisissez le nouveau mot de passe" v-model="password1" autocomplete="new-password" />
                    </div>

                    <div class="p-field">
                        <label for="password2" >Vérification mot de passe</label>
                        <InputText name="password2" :class="isNotValidPassword?'p-invalid':''" type="password" placeholder="Saisissez le même mot de passe" v-model="password2" autocomplete="new-password" />
                    </div>

                    <!--  -->
                    <div>{{errorMessage}}</div>
                    <!--  -->
                    
                </div>

                <div class="p-d-flex p-jc-end">
                    
                    <button class="btn btn-danger btn-block"  @click.prevent="fnClose">
                        Annuler
                    </button> &nbsp;
                    <button class="btn btn-primary btn-block" :disabled="isNotValidPassword" @click.prevent="fnApplyChangePassword">
                        Enregistrer
                    </button>

                </div>

    </Dialog>

</template>

<!-- ########################## <Script> ########################## -->
<script>
//--------------------------- Imports --------------------------

import { Button } from '@progress/kendo-vue-buttons';

import Password from 'primevue/password';
import AuthService from '../0-auth-components/auth.service';

export default {
    //---------------------------- props ------------------------------
    
    //--------------------------- Components --------------------------
    components: {
        Password
    },
    //--------------------------- Data --------------------------
    data() {
        return {
            errorMessage: "",
            passwordActuel : "",
            password1: "",
            password2: ""

        } // end result
    }, //end data()
    //--------------------------- emits -----------------------------
    emits: ["close","save"],
    //--------------------------- computed --------------------------
    computed:
    {
        username()
        {
            return this.currentUser.username;
        },
        password: function()
        {
            return (this.password1 !== "" && this.password1 === this.password2) ? this.password1:"";
        },
        isNotValidPassword: function()
        {
            return this.password == "" ? true : false;
        },
        verificationClass: function()
        {
            if(this.password1 == "" && this.password2 == "" ){
                return "";
            }
            return (this.password == "")?"pi pi-times red":"pi pi-check green";
        },
    },
    //--------------------------- Mounted --------------------------
    mounted() {
        this.log("----------- ChangePassword  mounted()");

  }, //end mounted
    //--------------------------- Created --------------------------
    created() {
        this.log("----------- ChangePassword created()");
        this.passwordActuel = "";
        this.password1 = "";
        this.password2 = "";
    },
    //--------------------------- Methods --------------------------
    methods: {

        fnClose: function()
        {
            this.passwordActuel = "";
            this.password1 = "";
            this.password2 = "";
            this.$emit('close');
        },
        fnApplyChangePassword: function(e)
        {
            this.log("ChangePassword.vue => fnApplyChangePassword");
            var user = {
                username : this.username,
                oldPassword: this.passwordActuel,
                password: this.password1
            };

            AuthService.asyncResetMyPassword(user)
            .then(
                (response) => {
                    this.log("Reset success: " + response.data.token);
                    this.log("response.data: " + response.data);
                    this.errorMessage = "Mot de pass de '"+user.username+"' a été réinitialisé avec succès";
                    
                    this.$emit('save',e);  
                },
                (error) => {
                    var errorMessage = error.message;
                    this.log("Reset error: " + errorMessage);
                    this.errorMessage = "Mot de passe actuel incorrect ! ";
                    this.$toast.add({severity:'warn', summary: 'Opération échoué', detail:this.errorMessage, life: 3000});
                    
                }
            )
            .catch((error) => { 
                this.log(error.response.data);  
                this.log(error.response.status);  
                this.log(error.response.headers); 
            });
                      
        }
      

    }
  
    //---------------------------
} //end export default
</script>

<!-- ########################## <Style> ########################## -->
<style >

.green
{
    color:green;
}

.red
{
    color:red;
}

</style>