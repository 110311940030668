import axios from 'axios';
import authHeader from './auth-header.js';
const VUE_APP_API_URL = process.env.VUE_APP_API_URL;

class AuthService {
  login(user) {

    console.log("--------------------NODE_ENV:"+process.env.NODE_ENV);
    console.log("--------------------VUE_APP_API_URL:"+process.env.VUE_APP_API_URL);

    return axios
      .post(VUE_APP_API_URL + 'auth/authenticate', {
        username: user.username,
        password: user.password
      })
      .then(response => {
        
        if (response.data.token) {
          console.log("Login success: " + response.data.token);
          response.data.tokenClientDate = new Date();
          localStorage.setItem('user', JSON.stringify(response.data));
        }

        console.log("response.data: " + response.data);

        return response.data;
      });
  }

  asyncResetMyPassword(user) {

    console.log("--------------------NODE_ENV:"+process.env.NODE_ENV);
    console.log("--------------------VUE_APP_API_URL:"+process.env.VUE_APP_API_URL);

    return axios.post(VUE_APP_API_URL + 'auth/resetmypassword', user, { body:user,headers: authHeader() });
  }

  asyncResetAdminPassword(user) {

    console.log("--------------------NODE_ENV:"+process.env.NODE_ENV);
    console.log("--------------------VUE_APP_API_URL:"+process.env.VUE_APP_API_URL);

    return axios.post(VUE_APP_API_URL + 'auth/resetadminpassword', user, { body:user,headers: authHeader() });
  }

  asyncResetClientPassword(user) {

    console.log("--------------------NODE_ENV:"+process.env.NODE_ENV);
    console.log("--------------------VUE_APP_API_URL:"+process.env.VUE_APP_API_URL);

    return axios.post(VUE_APP_API_URL + 'auth/resetclientpassword', user, { body:user,headers: authHeader() });
  }

  asyncResetClientInforiskPassword(password,societeId) {

    console.log("--------------------NODE_ENV:"+process.env.NODE_ENV);
    console.log("--------------------VUE_APP_API_URL:"+process.env.VUE_APP_API_URL);

    return axios.post(VUE_APP_API_URL + 'reinitializePasswordInforisk/'+password+'/'+societeId,{}, { headers: authHeader() });
  }

  logout() {
    localStorage.removeItem('user');
  }

  register(user) {
    return axios.post(VUE_APP_API_URL + 'auth/signup', {
      username: user.username,
      email: user.email,
      password: user.password
    });
  }
}

export default new AuthService();
