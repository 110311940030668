
export const clientScoringStoreModule = {
  namespaced: true,
  state: {
    IFsList: [],
    IQsList: [],
    currentClient : {},
    bilanJson : {},
    recommandations: [],
    limiteCredits:[],
    validBilansSelection: false,
    flagSavedNouveauScore:false,
    historiqueScoreActuel:{}
  },
  actions: {
    
  },
  mutations: {
    reset(state)
    {
      state.IFsList=[];
      state.IQsList= [];
      state.currentClient ={};
      state.bilanJson ={};
      state.recommandations= [];
      state.limiteCredits = []
      state.validBilansSelection= false;
      state.flagSavedNouveauScore = false;
      state.historiqueScoreActuel = {}
      console.log("@@@@ Cleared the store clientScoringStoreModule");
    },
    resetIndicateurs(state)
    {
      state.IFsList=[];
      state.IQsList= [];
      console.log("@@@@ resetIndicateurs IFsList & IQsList");
    },
    setValidBilansSelection(state, validBilansSelection) {
      state.validBilansSelection = validBilansSelection;
    },
    setCurrentClient(state, currentClient) {
      state.currentClient = currentClient;
    },
    setScoreActuel(state, scoreActuel)
    {
      state.historiqueScoreActuel = scoreActuel
    },
    setBilanJson(state, bilanJson) {
      state.bilanJson = bilanJson;
    },
    setFlagSavedNouveauScore(state, flagSavedNouveauScore) {
      state.flagSavedNouveauScore = flagSavedNouveauScore;
    },
    setRecommandations(state, recommandations) {
      state.recommandations = recommandations;
    },
    setLimiteCredits(state, limiteCredits) {
      state.limiteCredits = limiteCredits;
    },
    updateIFsList(state, IFNote) {
        var found =  false;

        for (let index = 0;  index < state.IFsList.length; index++) {
          const element = state.IFsList[index];
          if(element.id == IFNote.id){
              found = true;
              console.log("updateIFsList  found " + IFNote.valeur);
              element.note = IFNote.note;
              element.valeur = IFNote.valeur;
          }
        }
        
        if(!found)  {
          console.log("updateIFsList  not found " + IFNote.valeur);
          state.IFsList.push(IFNote);
        }
    },
    updateIQsList(state, IQNote) {
        var found =  false;

        for (let index = 0; index < state.IQsList.length; index++) {
          const element = state.IQsList[index];
          if(element.id == IQNote.id){
              found = true;
              element.note = IQNote.note;
              element.selected = IQNote.selected;
              element['listeChoixId'] = IQNote.listeChoixId;
          }
        }
        if(!found)  {
          IQNote.selected = false;
          state.IQsList.push(IQNote);
        }
    }
  },
  getters: {

    totalEchellesIFs: state => {
      let total = 0;
      for (let index = 0; index < state.IFsList.length; index++) {
        const element = state.IFsList[index];
        total += element.echelleIndicateur;
      }
      return total;
    },

 
    totalEchellesIQs: state => {
      let total = 0;
      for (let index = 0; index < state.IQsList.length; index++) {
        const element = state.IQsList[index];
        total += element.echelleIndicateur;
      }
      return total;
    },
  }
};
