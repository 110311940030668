<template>
  
    <Toast/>
    <ConfirmDialog></ConfirmDialog>
    <div class="mainApp">
      <router-view />
    </div>
    <Footer></Footer>

</template>

<script>

import '@progress/kendo-theme-default/dist/all.css';
import Footer from "./1-common-components/Footer.vue";
import CRUDService from "./1-common-components/crud.service";
import ConfirmDialog from 'primevue/confirmdialog';

export default {
  components: {
      Footer,
      ConfirmDialog
    },
  computed: {
  },
  mounted()
  {
    
    var endpoint = "currentclientcustom";
            
    CRUDService.get(0, endpoint).then(
        (response) => {
            this.log("----------------- fnLoadClient reponse");
            this.log(response.data);
            this.$store.commit('clientScoringStoreModule/setCurrentClient', response.data);
        },
        (error) => {
            this.errorMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        }
    );
  }
};
</script>


<style>

/* @import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap'); */
@font-face {
  font-family: "Poppins";
  src: local("Poppins"),
   url(./assets/fonts/Poppins-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "Roboto";
  src: local("Roboto"),
   url(./assets/fonts/Roboto-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "Righteous";
  src: local("Righteous"),
  url(./assets/fonts/Righteous-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "DancingScript";
  src: local("DancingScript"),
  url(./assets/fonts/DancingScript-Regular.ttf) format("truetype");
}

body, button, input {
    font-family: Poppins, Nunito, Oxygen, BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif !important;
}

.pageBodyContainer{
  padding: 5px 10px 30px 10px;
}

.p-datatable-tbody td .p-button
{
    margin:2px;
}


.mainApp
{
  min-height: 100vh;
}

.pageTitle {
    background-color: #efefef;
    padding-left: 8px;
    color: #2196f3;
    font-weight: bold;
    font-size: 18px;
    border-radius: 4px;
    padding-left: 10px;
}

.divPanel {
    /* border: 1px solid rgb(61, 87, 216); */
    /* border: 1px solid rgb(61, 87, 216); */
    border: 1px solid rgb(33 150 243);
    border-radius: 4px;
    padding: 1rem;
    margin: 10px 0px;
    
}

.topRightCorner
{
    /* Should also make the parent div position:relative */
    position:absolute;
    top:0px;
    right:0px;
}
.topLeftCorner
{
    /* Should also make the parent div position:relative */
    position:absolute;
    top:0px;
    left:0px;
}

div.scroll {          
                overflow-x: hidden;
                overflow-y: auto;
                text-align:justify;
            }


    .panel {
        /* margin-bottom: 20px; */
        background-color: #fff;
        border: 1px solid transparent;
        border-radius: 4px;
        -webkit-box-shadow: 0 1px 1px rgb(0 0 0 / 5%);
        box-shadow: 0 1px 1px rgb(0 0 0 / 5%);
    }

.tabStribHeader
{
    color: rgb(33 150 243);
    font-size: 18px;
    border-color: #f35800;
    border-bottom-width: 3px;
    border-radius: 4px;
    cursor: pointer;
    vertical-align: middle;
    border-bottom-color: red;
    font-weight: bold;
}
.tabStribHeader>span
{
  margin-top: 6px;
}
  .panel-default>.panel-heading {
    /* color: rgb(61, 87, 216); */
    font-size: 18px;
    background-color: #f5f5f5;
    border-color: #f35800;
    border-bottom-width: 3px;
    border-radius: 4px;
    }

    .panel-heading {
        padding: 10px 15px;
        border-bottom: 1px solid transparent;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
    }


    .clientDdlClass
    {
        width:100%;
    }


  .formuleValide
  {
      border-color:#c5efb8;
      border-width: 0.13em;
      background-color: rgb(182 255 160 / 25%);
  }

  .formuleNonValide
  {
      border-color:rgb(255 153 122);
      border-width: 0.13em;
      background-color: rgb(245, 191, 181);
  }

 .modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
}

.error-feedback {
  color: red;
}

/* START TOOLTIP STYLES */
[tooltip] {
  position: relative; /* opinion 1 */
}

/* Applies to all tooltips */
[tooltip]::before,
[tooltip]::after {
  text-transform: none; /* opinion 2 */
  font-size: .9em; /* opinion 3 */
  line-height: 1;
  user-select: none;
  pointer-events: none;
  position: absolute;
  display: none;
  opacity: 0;
}
[tooltip]::before {
  content: '';
  border: 5px solid transparent; /* opinion 4 */
  z-index: 1001; /* absurdity 1 */
}
[tooltip]::after {
  content: attr(tooltip); /* magic! */
  
  /* most of the rest of this is opinion */
  font-family: Helvetica, sans-serif;
  text-align: center;
  
  /* 
    Let the content set the size of the tooltips 
    but this will also keep them from being obnoxious
    */
  min-width: 3em;
  max-width: 40em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 1ch 1.5ch;
  border-radius: .3ch;
  box-shadow: 0 1em 2em -.5em rgba(0, 0, 0, 0.35);
  background: #333;
  color: #fff;
  z-index:9999999999999999999; /* absurdity 2 */
}

/* Make the tooltips respond to hover */
[tooltip]:hover::before,
[tooltip]:hover::after {
  display: block;
}

/* don't show empty tooltips */
[tooltip='']::before,
[tooltip='']::after {
  display: none !important;
}

/* FLOW: UP */
[tooltip]:not([flow])::before,
[tooltip][flow^="up"]::before {
  bottom: 100%;
  border-bottom-width: 0;
  border-top-color: #333;
}
[tooltip]:not([flow])::after,
[tooltip][flow^="up"]::after {
  bottom: calc(100% + 5px);
}
[tooltip]:not([flow])::before,
[tooltip]:not([flow])::after,
[tooltip][flow^="up"]::before,
[tooltip][flow^="up"]::after {
  left: 50%;
  transform: translate(-50%, -.5em);
}

/* FLOW: DOWN */
[tooltip][flow^="down"]::before {
  top: 100%;
  border-top-width: 0;
  border-bottom-color: #333;
}
[tooltip][flow^="down"]::after {
  top: calc(100% + 5px);
}
[tooltip][flow^="down"]::before,
[tooltip][flow^="down"]::after {
  left: 50%;
  transform: translate(-50%, .5em);
}

/* FLOW: LEFT */
[tooltip][flow^="left"]::before {
  top: 50%;
  border-right-width: 0;
  border-left-color: #333;
  left: calc(0em - 5px);
  transform: translate(-.5em, -50%);
}
[tooltip][flow^="left"]::after {
  top: 50%;
  right: calc(100% + 5px);
  transform: translate(-.5em, -50%);
}

/* FLOW: RIGHT */
[tooltip][flow^="right"]::before {
  top: 50%;
  border-left-width: 0;
  border-right-color: #333;
  right: calc(0em - 5px);
  transform: translate(.5em, -50%);
}
[tooltip][flow^="right"]::after {
  top: 50%;
  left: calc(100% + 5px);
  transform: translate(.5em, -50%);
}

/* KEYFRAMES */
@keyframes tooltips-vert {
  to {
    opacity: .9;
    transform: translate(-50%, 0);
  }
}

@keyframes tooltips-horz {
  to {
    opacity: .9;
    transform: translate(0, -50%);
  }
}

/* FX All The Things */ 
[tooltip]:not([flow]):hover::before,
[tooltip]:not([flow]):hover::after,
[tooltip][flow^="up"]:hover::before,
[tooltip][flow^="up"]:hover::after,
[tooltip][flow^="down"]:hover::before,
[tooltip][flow^="down"]:hover::after {
  animation: tooltips-vert 300ms ease-out forwards;
}

[tooltip][flow^="left"]:hover::before,
[tooltip][flow^="left"]:hover::after,
[tooltip][flow^="right"]:hover::before,
[tooltip][flow^="right"]:hover::after {
  animation: tooltips-horz 300ms ease-out forwards;
}

</style>



