import { createStore } from "vuex";
import { authStoreModule } from "./0-auth-components/authStore.module";
import { clientSettingsStoreModule } from "./2-admin-components/ClientSettings/ClientSettingsStore.module";
import { clientScoringStoreModule } from "./3-client-components/ClientScoring/ClientScoringStore.module";

const store = createStore({
  modules: {
    authStoreModule,
    clientSettingsStoreModule,
    clientScoringStoreModule
  },
  
});



export default store;
