<template>
<div class="loginwrapper">
  <div class="logincenter">

  <div class="container">
  <div class="filter-form "> 
  <div class="col-md-12">
    <div class="card card-container">
      <img
        id="profile-img"
        src="../assets/test4.png"
        class="logo-img-card"
        style="text-align:center;background-color: #d30400;width:150px"
      />
      <!-- <h2 class="loginLogoTitle">MY SCORE</h2> -->
      <Form @submit="handleLogin" :validation-schema="schema" >

        <!-- Inputs -->
        <div class="form-group">
          <label for="username">Login</label>
          <Field name="username" type="text" class="form-control" />
          <ErrorMessage name="username" class="error-feedback" />
        </div>
        
        <div class="form-group">
          <label for="password">Mot de passe</label>

          <div style=" display:flex">
            <Field id="password" name="password" type="password" class="form-control" />
            &nbsp;
            <i class="pi pi-eye" v-if="shown" style="line-height: 2;" @click="hidePassword"></i>
            <i class="pi pi-eye-slash" v-else style="line-height: 2;" @click="showPassword"></i>
          </div>

          <ErrorMessage name="password" class="error-feedback" />
        </div>

        <!-- Button -->
        <div class="form-group" style = "text-align:center; padding-top:30px">
          <button class="btn btn-primary btn-block" :disabled="loading">
            <span
              v-show="loading"
              class="spinner-border spinner-border-sm"
            ></span>
            <span>Se connecter</span>
          </button>
          
          &nbsp;

          <button class="btn btn-block" style="background-color: lightgray;" rounded outlined aria-label="Notification" @click="$router.push('/')">
            <span
              class="pi pi-home"
            ></span>
          </button>
        
        </div>

        <!-- Button -->
        <div class="form-group">
          <div v-if="message" class="alert alert-danger" role="alert">
            {{ message }}
          </div>
        </div>

      </Form>
    </div>
  </div>
  </div>
  </div>
  </div>
  </div>
</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";

export default {
  name: "Login",
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    const schema = yup.object().shape({
      username: yup.string().required("Login est requis !"),
      password: yup.string().required("Mot de passe est requis !"),
    });

    return {
      loading: false,
      message: "",
      schema,
      shown:false
    };
  },
  
  computed: {
    loggedIn() {
      return this.$store.state.authStoreModule.status.loggedIn;
    },
    currentUser() {
      var currentClient = this.$store.state.clientScoringStoreModule.currentClient;
      var obj = {...this.$store.state.authStoreModule.user};
      obj.jsonUserRights = obj.jsonUserRights?JSON.parse(obj.jsonUserRights):{}

      if(currentClient && !currentClient.activerWorkflowValidation)
      {
        obj.jsonUserRights['validationQualitative'] = false
        obj.jsonUserRights['validationFinanciere'] = false
      }
      
      return obj;
    }
  },
  created() {
    if (this.loggedIn) {
      if(this.currentUser.application === "AdminApplication"){
        this.$router.push("/admin-clients");
      }
      else {
        this.$router.push("/client-societes");
      }
    }
  },
  mounted()
  {

  },

  methods: {
    showPassword()
    {
      let type = password.getAttribute('type') === 'password' ? 'text' : 'password';
      $('#password').attr('type',type)

      this.shown = true
    },
    hidePassword()
    {
      let type = password.getAttribute('type') === 'password' ? 'text' : 'password';
      $('#password').attr('type',type)

      this.shown = false
    },
    handleLogin(user) {
      // 
      this.loading = true;

      this.$store.dispatch("authStoreModule/login", user).then(
        () => {
          this.log(this.currentUser);
          if(this.currentUser.application === "AdminApplication"){
            this.$router.push("/admin-clients");
          }
          else {
            this.$router.push("/client-societes");
          }
          
        },
        (error) => {
          this.loading = false;
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
  },
};
</script>

<style scoped>
label {
  display: block;
  margin-top: 10px;
}

.loginwrapper{
  background-image: url('../assets/images/background-2462434.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 100vh;
  position: relative;
}


.logincenter {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width:800px;
  
}


.header{
  width: 100%;
  height: 50px;
}

.logo{
    width: 96px;
    height: 96px;
}

.filter-form {
    background-color: rgba(0,0,0,.36);
    border-radius: 15px;
    padding: 30px 0px 30px 0;
    
}


.loginLogoTitle{
      color: #1452af;
      text-align:center; 
      font-family: 'Righteous', 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif; 
      font-weight: bold;
}


.card-container.card {
  max-width: 350px !important;
  padding: 40px 40px;
  margin-top:50px;
  border-radius: 15px;
  
}

.card {
  background-color: #f7f7f7;
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  /* margin-top: 50px; */
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.logo-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  /* -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%; */
}






</style>
