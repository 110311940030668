<template>

  <div class="footer">
    © {{year}} MY SCORE - Tous Droits Réservés | <span v-tooltip.top="{ value: dbName }">Développé</span> par <a href="https://ilemgroup.com"> ilem Group</a>
     | Contact: <a HREF="mailto:contact@myscore.ma">contact@myscore.ma</a>&nbsp;<a HREF="mailto:contact@myscore.ma"><i style="font-size:10px" class="pi pi-envelope"></i></a>
    
  </div>
</template>

<script>
import CRUDService from "../1-common-components/crud.service";

export default {
    name: "Footer",
    //--------------------------- Components --------------------------
    components: {
      
    },
    //--------------------------- Data --------------------------
    data() {
        return {
            year : new Date().getFullYear(),
            dbName: ""

        }
    }, //end data()
    //--------------------------- Mounted --------------------------
    created() {
        // if (location.href.toLowerCase().indexOf("//myscore.ma") != -1 &&  location.href.toLowerCase().indexOf("https:") == -1) {
        //     console.log("Redirect from http to https from Footer Created event");
        //     location.replace('https://www.myscore.ma/login');
        // }
        // else if (location.href.toLowerCase().indexOf("myscore.ma") != -1 &&  location.href.toLowerCase().indexOf("https:") == -1) {
        //     console.log("Redirect from http to https from Footer Created event");
        //     location.replace(`https:${location.href.substring(location.protocol.length)}`);
        // }
    }, //end mounted
    //--------------------------- Mounted --------------------------
    mounted() {
        this.loadDatabaseName();
    }, //end mounted
    //--------------------------- methods --------------------------
    methods: {
        loadDatabaseName() {
            this.log("------loadDatabaseName");
            CRUDService.getDatabaseName().then(
                (response) => {
                    this.log(response.data);
                    this.dbName = response.data;
                },
                (error) => {
                    this.errorMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                }
            );
        },
    }
}
</script>

<style scoped>
.footer{
  text-align: center;
  color:grey;
  font-size:15px;
  background-color: #ececee;
  opacity: .6;
  bottom: 0;
  position: fixed;
  width: 100%;

}

a, a:visited, a:hover {
  color: grey;
}
</style>
