import "primevue/resources/themes/saga-blue/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";
import 'primeflex/primeflex.css';
import { createApp } from "vue";
import underscore from 'underscore';
import { _ } from 'vue-underscore';
import App from "./App.vue";
import router from "./router.js";
import store from "./store.js";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from './plugins/font-awesome'
import '@progress/kendo-ui'
import '@progress/kendo-theme-default/dist/all.css'
import { Calendar } from '@progress/kendo-dateinputs-vue-wrapper'
import '@progress/kendo-ui/js/messages/kendo.messages.fr-FR.js'
import { plugin as Slicksort } from 'vue-slicksort';
import PrimeVue from 'primevue/config';
import ToastService from 'primevue/toastservice';
import ConfirmationService from 'primevue/confirmationservice';
import Toast from 'primevue/toast';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog'
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Toolbar from 'primevue/toolbar';
import InputText from 'primevue/inputtext';
import Textarea from 'primevue/textarea';
import InputSwitch from 'primevue/inputswitch';
import InputMask from 'primevue/inputmask';
import InputNumber from 'primevue/inputnumber';
import PCalendar from 'primevue/calendar';
import Dropdown from 'primevue/dropdown';
import Checkbox from 'primevue/checkbox';
import Tooltip from 'primevue/tooltip';
import moment from 'moment';


// import VueI18n from 'vue-i18n';

// import { NumericTextBox } from '@progress/kendo-inputs-vue-wrapper';
// import { NumericTextBox } from '@progress/kendo-vue-inputs';

// const messages = {
//     'en': {},
//     'fr': {}
// };

// const i18n = new VueI18n({
//     locale: 'en', // set locale
//     fallbackLocale: 'fr', // set fallback locale
//     messages, // set locale messages
// });


var vueApp = createApp(App)
    //.use(VueI18n)
    .use(moment)
    .use(router)
    .use(store)
    .use(underscore)
    .use(PrimeVue, {
        locale: { closeText: 'Fermer', prevText: 'Précédent', nextText: 'Suivant', monthNames: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'], monthNamesShort: ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Jun', 'Jul', 'Aoû', 'Sep', 'Oct', 'Nov', 'Déc'], dayNames: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'], dayNamesShort: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'], dayNamesMin: ['D', 'L', 'M', 'M', 'J', 'V', 'S'], weekHeader: 'Semaine', firstDay: 1, isRTL: false, showMonthAfterYear: false, yearSuffix: '', timeOnlyTitle: 'Choisir l\'heure', timeText: 'Heure', hourText: 'Heures', minuteText: 'Minutes', secondText: 'Secondes', currentText: 'Maintenant', ampm: false, month: 'Mois', week: 'Semaine', day: 'Jour', allDayText: 'Toute la journée' }
    })
    .use(ToastService)
    .use(ConfirmationService)
    .use(_)
    .use(Slicksort)
    .component('Toast', Toast)
    .component("font-awesome-icon", FontAwesomeIcon)
    .component(Calendar.name, Calendar)
    .component('P-Button', Button)
    .component('DataTable', DataTable)
    .component('Column', Column)
    .component('Toolbar', Toolbar)
    .component('InputText', InputText)
    .component('Textarea', Textarea)
    .component('InputSwitch', InputSwitch)
    .component('InputMask', InputMask)
    .component('InputNumber', InputNumber)
    .component('Dropdown', Dropdown)
    .component('Dialog', Dialog)
    .component('Checkbox', Checkbox)
    .component('PCalendar', PCalendar)
    .directive('tooltip', Tooltip)
    // .component('NumericTextBox', NumericTextBox)


vueApp.mixin({
    computed: {
        currentUser() {
            var obj = {...this.$store.state.authStoreModule.user};
            obj.jsonUserRights = obj.jsonUserRights?JSON.parse(obj.jsonUserRights):{}
            
            return obj;
            // return this.$store.state.authStoreModule.user;
        },
        isAdminApplication() {
            return (this.currentUser.application == "AdminApplication");
        },
        isAdminUtilisateursClient() {
            return this.currentUser.jsonUserRights.adminUtilisateursClient;
        },
    },
    methods: {
        log(message) {
            //console.log(message);
        },
        convertUTCDateToLocalDate(utcDate) {
                if(utcDate)
                {
                    var localDate = moment.utc(utcDate).local()
                    return localDate.toDate();   
                }
                
                return null;
            
        },
        parseDateAsMoment: function(dateString) {
            return moment(dateString, 'DD/MM/YYYY');
        },
        getMomentFromDate: function(date) {
            return moment(date);
        },
        getUtcDate(aDate) {
            aDate = new Date(aDate);
            return new Date(Date.UTC(aDate.getFullYear(), aDate.getMonth(), aDate.getDate()));
        },
        toastSuccess(message) {
            this.$toast.add({ severity: 'success', summary: 'Opération réussie', detail: message, life: 10000 });
        },
        toastInfo(message) {
            this.$toast.add({ severity: 'info', summary: 'Opération réussie', detail: message, life: 10000 });
        },
        toastWarn(message) {
            this.$toast.add({ severity: 'warn', summary: 'Attention', detail: message, life: 10000 });
        },
        getValidNumberOrReturnZero(number)
        {
            if(isNaN(number))
            {
                return 0
            }
            else if(!number)
            {
                return 0
            }
            else
            {
                return number
            }
        },
        toastError(message) {
            this.$toast.add({ severity: 'error', summary: 'Opération échouée', detail: message, life: 3000 });
        },
        formatDecimal2(num) {
            return kendo.toString(num, "N2")
        },
        formatDecimal0(num) {
            return kendo.toString(num, "N0").replaceAll(',',' ')
        },
        formatDate(dateString) {
            return kendo.toString(new Date(dateString), "dd/MM/yyyy");
        },
        formatDatetime(dateString) {
            return kendo.toString(new Date(dateString), "dd/MM/yyyy HH:mm");
        },
        isNumeric(n) {
            return !isNaN(parseFloat(n)) && isFinite(n);
        },
        //limits the number of calls per interval
        debounce(func, wait, immediate, context) {
            debugger;
            var result;
            var timeout = null;
            return function() {
                var ctx = context || this, args = arguments;
                var later = function() {
                    timeout = null;
                    if (!immediate) result = func.apply(ctx, args);
                };
                var callNow = immediate && !timeout;
                // Tant que la fonction est appelée, on reset le timeout.
                clearTimeout(timeout);
                timeout = setTimeout(later, wait);
                if (callNow) result = func.apply(ctx, args);
                return result;
            };
        },
        throttle(func, wait, leading, trailing, context) {
            var ctx, args, result;
            var timeout = null;
            var previous = 0;
            var later = function() {
                previous = new Date;
                timeout = null;
                result = func.apply(ctx, args);
            };
            return function() {
                var now = new Date;
                if (!previous && !leading) previous = now;
                var remaining = wait - (now - previous);
                ctx = context || this;
                args = arguments;
                // Si la période d'attente est écoulée
                if (remaining <= 0) {
                    // Réinitialiser les compteurs
                    clearTimeout(timeout);
                    timeout = null;
                    // Enregistrer le moment du dernier appel
                    previous = now;
                    // Appeler la fonction
                    result = func.apply(ctx, args);
                } else if (!timeout && trailing) {
                    // Sinon on s’endort pendant le temps restant
                    timeout = setTimeout(later, remaining);
                }
                return result;
            };
        },
        arrondiDixMilles(n)
        {
            
            if(!n || isNaN(n))
            {
                return 0;
            }

            var isNegative = (n<0)?true:false;

            // si negatif, le rendre positif pour appliquer les regles
            if(isNegative) {
                n = n * -1;
            }

            var divider = 1;

            var  numberLength = n.toString().length
            switch (numberLength) {
                case 1:
                    divider = 1
                    break;
                case 2:
                    divider = 1
                    break;
                case 3:
                    divider = 10
                    break;
                case 4:
                    divider = 100
                    break;
                case 5:
                    divider = 1000
                    break;
                default:
                    divider = 10000
                    break;
            }

            // si negatif, le remetre a un nombre negatif
            if(isNegative) {
                n = n * -1;
            }

            return parseInt(n/divider)*divider;
        },
        isInRole(roleName) {
            if (this.currentUser && this.currentUser['roles']) {
                return this.currentUser['roles'].includes(roleName);
            }

            return false;
        }
    }
});

vueApp.mount("#app");