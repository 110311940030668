import axios from 'axios';
// import { date } from 'yup/lib/locale';
import authHeader from '../0-auth-components/auth-header.js';

const API_URL = process.env.VUE_APP_API_URL;

class CRUDService {

  getAuthHeader()
  {
    return authHeader();
  }

  // ############################## CRUD SERVICE ###############################
  list(endpoint) {
    var url = API_URL + endpoint;
    console.log("---------- list url:" + url);
    return axios.get(url, { headers: authHeader() });
  }

  listData(endpoint,data) {
    var url = API_URL + endpoint;
    console.log("---------- list url:" + url);
    return axios.post(url,data, { headers: authHeader() });
  }

  get(data,endpoint) {
    let id = data;
    if(typeof data === 'object' && data !== null)
    {
      id = data.id;
    }
    
    var url = API_URL + endpoint+'/'+id
    console.log("---------- get url: "+url);
    return axios.get(url, { headers: authHeader() });
  }

  update(data,endpoint) {
    let id = data.id;
    var url = API_URL + endpoint+'/'+id;
    console.log("---------- update url: "+url);
    console.log("---------- update data: ");

    if( Object.prototype.hasOwnProperty.call(data, "dateModification") ) {
      
      data.dateModification = new Date();
    }

    console.log(data);
    return axios.put(url, data, { headers: authHeader() });
  }

  create(data,endpoint) {
    
    data.createur = this.getCreateur();
    
    data.dateCreation = new Date();

    // if( Object.prototype.hasOwnProperty.call(data, "poidsScoreFinancier") &&
    //     Object.prototype.hasOwnProperty.call(data, "poidsScoreQualitatif") &&
    //     !data.poidsScoreFinancier &&
    //     !data.poidsScoreQualitatif ) 
    // {
    //   data.poidsScoreFinancier = 50;
    //   data.poidsScoreQualitatif = 50;
    // }

    if( Object.prototype.hasOwnProperty.call(data, "dateDerniereConnexion") ) {
      data.dateDerniereConnexion = null;
    }

    if( Object.prototype.hasOwnProperty.call(data, "dateModification") ) {
      
      data.dateModification = new Date();
    }

    

    var url = API_URL + endpoint;
    console.log("---------- create url: "+url);
    console.log("---------- create data: ");
    console.log(data);
    // 
    return axios.post(url, data, { headers: authHeader() });
  }

  delete(data,endpoint) {
    let id = data;
    if(typeof data === 'object' && data !== null)
    {
      id = data.id;
    }
    var url = API_URL + endpoint+'/'+ id;
    console.log("---------- delete url: "+url);
    return axios.delete(url, { body:data,headers: authHeader() });
  }
  
  // ############################## COMMUN ###############################
  getCreateur()
  {
    let user = JSON.parse(localStorage.getItem('user'));
    return user.username + " ("+user.prenom + " "+user.nom+")";
  }

  getDatabaseName()
  {
    var url = API_URL + "db";
    return axios.post(url, {}, { headers: authHeader() });
  }

}

export default new CRUDService();
