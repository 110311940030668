<!-- ########################## <Template> ########################## -->
<template lang="">

    <Header></Header>
    <div class="pageTitle" > 
        <font-awesome-icon icon="caret-right" /> 
        Gestion des administrateurs Back-Office
    </div>

    <div class="pageBodyContainer"> 
        <!--  -->
        
        <!--  -->
        From entityModel:
        <div>
            <div class="card">
                <DataTable ref="dt" :value="gridData"  dataKey="id" 
                    :filters="filters"
                    responsiveLayout="scroll" class="p-datatable-sm">
                    <template #header>
                            <h5 class="p-mb-2 p-m-md-0 p-as-md-center">
                                <P-Button label="Ajout date" icon="pi pi-plus" class="p-button-outlined p-button-success p-mr-2" @click="saveGridItem({id:0})" />
                            </h5>
                    </template>

                    <Column field="dateCreation" header="dateTime" :sortable="true" style="min-width:12rem"></Column>
                    
                </DataTable>
            </div>
            
            From DataContext:
            <div class="card">
                <DataTable ref="dt" :value="gridDataContext"  dataKey="id" 
                    :filters="filters"
                    responsiveLayout="scroll" class="p-datatable-sm">
                    <template #header>
                            <h5 class="p-mb-2 p-m-md-0 p-as-md-center">
                                <P-Button label="Ajout date Dbcontext" icon="pi pi-plus" class="p-button-outlined p-button-success p-mr-2" @click="saveGridItemDbContext({id:0})" />
                                <P-Button label="Ajout date Dbcontext en Utc" icon="pi pi-plus" class="p-button-outlined p-button-success p-mr-2" @click="saveGridItemDbContextUtc({id:0})" />
                            </h5>
                    </template>

                    <Column field="dateCreation" header="dateTime" :sortable="true" style="min-width:12rem"></Column>
                    
                </DataTable>
            </div>

            

        </div>


        <div class="errorMessage" ref="errorMessage"> {{errorMessage}}</div>
    </div>

    
    
</template>

<!-- ########################## <Script> ########################## -->
<script>
//--------------------------- Imports --------------------------
import AuthService from '../0-auth-components/auth.service';
import Header from "./Header.vue";
import CRUDService from "../1-common-components/crud.service";
import { Grid, GridColumn } from '@progress/kendo-grid-vue-wrapper';
import { KendoDataSource } from '@progress/kendo-datasource-vue-wrapper';
import kendo from '@progress/kendo-ui'
import {_} from 'vue-underscore';
import {FilterMatchMode,FilterOperator} from 'primevue/api';
import Dialog from 'primevue/dialog'
import Divider from 'primevue/divider'
import ConfirmDialog from 'primevue/confirmdialog';
import { Field, Form, ErrorMessage, } from 'vee-validate';
import * as yup from 'yup';
import Dropdown from 'primevue/dropdown';
import Password  from 'primevue/password';

export default {
    name: "AdminClients",
    //--------------------------- Components --------------------------
    components: {
        Header, 
        Dialog,
        Divider,
        ConfirmDialog,
        'kendo-grid': Grid,
        'kendo-grid-column': GridColumn,
        'kendo-datasource': KendoDataSource,
        'V-Field':Field,
        'V-Form':Form,
        ErrorMessage,
        Dropdown,
        Password
    },
    //--------------------------- Data --------------------------
    data() {

        // Using yup to generate a validation schema
        // https://vee-validate.logaretm.com/v4/guide/validation#validation-schemas-with-yup
        const myValidationSchema = yup.object().shape({
                
                username: yup.string().max(50,"Maximum 50").required("Login est obligatoire !"),
                prenom: yup.string().max(50,"Maximum 50").required("Prénom est obligatoire !"),
                nom: yup.string().max(50,"Maximum 50").required("Nom est obligatoire !"),
                email: yup.string().email("Format email n'est pas valide").max(50,"Maximum 50").required("Email est obligatoire !"),
                
        });


        return {
            gridData: [],
            gridDataContext: [],
            showEditDialogFlag: false,
            selectedGridItem: {id:0},
            filters: {
                'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
            },
            errorMessage: "",
            myValidationSchema,
            listeDesClients: [],
            clientRole:"Consultation",
            userPassword: ""

        } // end result
    }, //end data()
    //--------------------------- computed --------------------------
    computed: {
        isEditForm()
        {
            return this.selectedGridItem.id > 0 ? true:false;
        }
    },
    //--------------------------- Mounted --------------------------
    mounted() {
        ;
        this.log("----------- mounted()");
        this.loadGridData();
        this.loadGridDataDbContext()
    }, //end mounted
    //--------------------------- Created --------------------------
    created() {
        this.log("----------- created()");
    },
    //--------------------------- Methods --------------------------
    methods: {
        
        //
        //========================= Manage CRUD Dialogs ===================================
        onSubmit(values, actions) {
            this.log("onSubmit");
            this.log(values);
            //manual validations
            if(this.isEditForm) {
                if(values.userPassword.length > 0)
                {
                    var r = confirm("Etes-vous sûr de vouloir modifier le mot de passe de l'utilisateur ?");
                    if (r == false) {
                        return;
                    }
                }
            }else {
                if(values.userPassword.length == 0)
                {
                    // set single field error
                    actions.setFieldError('userPassword', 'Mot de passe est obligatoire');
                    return;
                }
            }

            this.saveGridItem();
        },
        saveGridItem(selectedItem)
        {
            this.log("saveGridItem");
            this.log(selectedItem);

            CRUDService.create(selectedItem,'dateTimeTest').then(
                
                (response) => {
                    this.log(response.data);
                    this.gridData.push(selectedItem);
                },
                (error) => {
                    this.errorMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    this.errorMessage = (error.response && error.response.data)?error.response.data:error.response;
                    this.toastError("Echec création de l''enregistrement !");

                }
            );
            
        },
        openAddNewItem()
        {
            var clientId = this.listeDesClients.length > 0?this.listeDesClients[0].id:0;
            this.openEditDialog({id:0, roles:"Consultation", clientId:clientId});
        },
        openEditDialog(selectedItem){
            this.log("openEditDialog");
            this.openDialog(selectedItem);
        },
        openDialog(selectedItem){
            this.log("openDialog");
            this.log(selectedItem);
            this.showEditDialogFlag = true;
            this.selectedGridItem = selectedItem;
            this.selectedGridItemInitialState = { ...this.selectedGridItem }; //this is an object copy using decomposition
            this.userPassword = "";
        },
        closeEditDialog(){
            this.log("closeEditDialog");
            
            for (let index = 0; index < this.gridData.length; index++) {
                var element = this.gridData[index];
                if(element.id == this.selectedGridItem.id)
                {
                    this.log(this.selectedGridItemInitialState);
                    this.gridData[index] = this.selectedGridItemInitialState;
                    break;
                }
            }

            this.showEditDialogFlag = false;
        },
        confirmDeleteSelected(selectedItem){
            this.log("confirmDeleteSelected: " + selectedItem);
            this.log(selectedItem);
            this.selectedGridItem = selectedItem

            this.$confirm.require({
                message: 'Etes-vous sûr de vouloir supprimer le client '+this.selectedGridItem.nom+'?',
                header: 'Confirmation',
                icon: 'pi pi-info-circle',
                acceptLabel:'Oui',
                rejectLabel:'Non',
                acceptClass: 'p-button-danger',
                accept: () => {
                    this.deleteGridItem();
                },
                reject: () => {
                    this.$confirm.close();
                }
            });
        },
        deleteGridItem()
        {
            this.log("deleteGridItem");
            CRUDService.delete(this.selectedGridItem,'useradmin').then(
                (response) => {
                    this.log(response.data);
                    this.gridData = this.gridData.filter(d => d.id != this.selectedGridItem.id);
                    this.toastSuccess("Enregistrement supprimé avec succès");
                },
                (error) => {
                    this.toastError("Erreur suppression de l''enregisrement");
                    this.errorMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                }
            );
            
        },
        //==================================================================================
 
        loadGridData()
        {

            this.log("------loadGridData");
            CRUDService.list('dateTimeTest').then(
                (response) => {
                    this.gridData = response.data;
                    this.log(this.gridData);
                },
                (error) => {
                    this.errorMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                }
            );
        },
        loadGridDataDbContext()
        {
            var ref = this;
            this.log("------getDateTime");
            CRUDService.list('getDateTimeTestUtc').then(
                (response) => {
                    this.gridDataContext = response.data.map(function(d){
                        var obj = {...d}
                        obj['dateCreation'] = ref.convertUTCDateToLocalDate(obj['dateCreation'])
                        return obj
                    });;
                },
                (error) => {
                    this.errorMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                }
            );
        },

        saveGridItemDbContext(selectedItem)
        {
            var ref = this;
            this.log("createDateTimeTest");
            this.log(selectedItem);

            CRUDService.listData('createDateTimeTest',{}).then(function(response){
                if(response.data)
                {
                    //this.companyInforisk =  response.data;
                    ref.gridDataContext.push(response.data)
                }
                else
                {
                    alert("Erreur")
                }
            })
            
        },

        saveGridItemDbContextUtc(selectedItem)
        {
            var ref = this;

            this.log("createDateTimeTest");
            this.log(selectedItem);

            CRUDService.listData('createDateTimeTestUtc',{}).then(function(response){
                if(response.data)
                {
                    //this.companyInforisk =  response.data;
                    ref.gridDataContext.push(response.data)
                }
                else
                {
                    alert("Erreur")
                }
            })
            
        },

        //----------------------------------------------------------------------
        changePasswordSave: function(userLogin, password)
        {
            this.log("AdminUser.vue => changePasswordSave");
            this.log("login: "+ userLogin);
            this.log("password: "+ password);
            var user = {
                            username: userLogin,
                            password: password
                        };

            AuthService.asyncResetAdminPassword(user).then(
                (response) => {
                    this.log("Reset success: " + response.data.token);
                    this.log("response.data: " + response.data);
                    this.errorMessage = "Mot de passe de '"+user.username+"' a été réinitialisé avec succès";
                    this.toastSuccess("Mot de passe de '"+user.username+"' a été mis à jour avec succès");
                },
                (error) => {
                    this.log("Reset error: " + error);
                    this.errorMessage = "ERREUR: Mot de pass de '"+user.username+"' n'a pas été réinitialisé !! "+error;
                    this.toastError("Mise à jour du mot de passe de '"+user.username+"' a échoué");
                }
            );


        },
        //
    }
  
    //---------------------------
} //end export default
</script>

<!-- ########################## <Style> ########################## -->
<style >


label[for="createur"] {
    display: none;
}
</style>