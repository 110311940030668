import { createWebHistory, createRouter } from "vue-router";
import Login from "./0-auth-components/Login.vue";

import testdateTimeTable from './2-admin-components/testdateTimeTable.vue'
// lazy-loaded
const LoginTest = () =>
    import ("./0-auth-components/LoginTest.vue")
const AdminClients = () =>
    import ("./2-admin-components/AdminClients.vue")
const AdminClientUsers = () =>
    import ("./2-admin-components/AdminClientUsers.vue")
const AdminClientSettings = () =>
    import ("./2-admin-components/AdminClientSettings.vue")
const AdminUsers = () =>
    import ("./2-admin-components/AdminUsers.vue")

const ClientUsers = () =>
    import ("./3-client-components/ClientUsers.vue")
const ClientSocietes = () =>
    import ("./3-client-components/ClientSocietes.vue")
const ClientHistoriqueScore = () =>
    import ("./3-client-components/ClientHistoriqueScore.vue")
const ClientScoring = () =>
    import ("./3-client-components/ClientScoring/ClientScoring.vue")
const ClientBilan = () =>
    import ("./3-client-components/ClientBilan.vue")
const ClientSourcesExternes = () =>
    import ("./3-client-components/ClientSourcesExternes.vue")

const HomePage = () =>
    import ("./5-static-pages/HomePage.vue")

const routes = [
    { path: "/", name: "root", component: HomePage },
    { path: "/login", name: "login", component: Login },
    { path: "/login-test", name: "LoginTest", component: LoginTest },
    { path: "/home", name: "HomePage", component: HomePage },
    

    { path: "/admin-clients", name: "AdminClients", component: AdminClients },
    { path: "/admin-clientusers", name: "AdminClientUsers", component: AdminClientUsers },
    { path: "/admin-clientsettings", name: "AdminClientSettings", component: AdminClientSettings },
    { path: "/admin-users", name: "AdminUsers", component: AdminUsers },

    { path: "/client-users", name: "ClientUsers", component: ClientUsers },
    { path: "/client-societes", name: "ClientSocietes", component: ClientSocietes },
    { path: "/client-historique-score", name: "ClientHistoriqueScore", component: ClientHistoriqueScore },
    { path: "/client-scoring/:societeId/:scoreId", props: true, name: "ClientScoring", component: ClientScoring },
    { path: "/client-bilan/:societeId", props: true, name: "ClientBilan", component: ClientBilan },
    { path: "/client-sourcesexternes", props: true, name: "ClientSourcesExternes", component: ClientSourcesExternes },
    { path: "/test", props: true, name: "test", component: testdateTimeTable },
];



const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach((to, from, next) => {

    // 
    const publicPages = ['/login', '/login-test' ,'/home'];
    const authRequired = !publicPages.includes(to.path);
    const loggedIn = localStorage.getItem('user');

    // trying to access a restricted page + not logged in
    // redirect to login page
    if (authRequired && !loggedIn && to.name!='root') {
        next('/login');
        console.log("Redirect to /login from router.js");
    } else {
        // 
        var hasTokenExpired = false;
        if (loggedIn) {
            hasTokenExpired = Date.parse(JSON.parse(loggedIn).tokenExpiryDate) < Date.now();
        }

        if (hasTokenExpired) {
            localStorage.removeItem('user');
            console.log("############## Token expired, redirect to login");
            alert("Session expirée !");
            next('/login');
        } else {
            next();
        }
    }
});

export default router;